// module with default values and
//  methods to fill unfilled objects and extract them to request
import {
  AUTOMATED_UNDERWRITING_SYSTEM,
  compPaidBy,
  compPaidByTypes,
  LIEN_POSITIONS,
  loanPurposeSelect,
  loanTypes,
  lockPeriods,
  MI_RATE_CARDS_PRODUCT_CATEGORIES,
  occupancyCodes,
  PECreditGrade,
  PEPrepaymentPSTypeValues,
  propertyAttachmentCodes,
  propertyCodes,
  refinanceTypes,
  STATES,
  temporaryBuydownCodes,
  terms,
} from '@shared/constants';
import moment from 'moment-timezone';

export const displayLoanTypes = Object.freeze([
  {
    name: 'Conventional',
    type: loanTypes.Conventional,
    criteriaName: 'conventional',
  },
  { name: 'Jumbo', type: loanTypes.Jumbo, criteriaName: 'jumbo' },
  { name: 'NonQM', type: loanTypes.NonQM, criteriaName: 'nonQm' },
  { name: 'FHA', type: loanTypes.FHA, criteriaName: 'fha' },
  { name: 'VA', type: loanTypes.VA, criteriaName: 'va' },
  { name: 'USDA', type: loanTypes.USDA, criteriaName: 'usda' },
]);

export const defaultLoanPrograms = Object.freeze({
  [terms.TERM_120]: false,
  [terms.TERM_180]: false,
  [terms.TERM_240]: false,
  [terms.TERM_300]: false,
  [terms.TERM_360]: true,
});

export const defaultArmPrograms = Object.freeze({
  [terms.TERM_12]: false,
  [terms.TERM_36]: false,
  [terms.TERM_60]: false,
  [terms.TERM_84]: false,
  [terms.TERM_120]: true,
});

export const defaultValues = Object.freeze({
  loan: {
    purpose: undefined,
    refinancePurpose: undefined,
    amount: 0,
    downPayment: 0,
    ltv: 0,
    cltv: 0,
    hcltv: 0,
    purchasePrice: 0,
    propertyValue: 0,
    cashOutAmount: 0,
    secondAmount: 0,
    helocLineAmount: 0,
    helocDrawAmount: 0,
    isMortgageInsurancePaidByBorrower: true,
    waiveEscrow: 2,
    rollLenderFee: false,
    lenderFee: undefined,
    aus: undefined,
    position: undefined,
    prepaymentPenaltyPeriodMonths: 0,
    prepaymentPenaltyStructure: undefined,
    prepaymentPenaltyStructureType: PEPrepaymentPSTypeValues.NoPrepay,
    fhaFinancingOption: 'Finance',
    fhaTotalLoanAmount: 0,
    fhaTotalLTV: 0,
    fhaTotalCLTV: 0,
    fhaTotalHCLTV: 0,
    fhaMortgageInsurancePremium: 0,
    fhaMortgageInsurancePremiumAmount: 0,
    fhaFinanceAmount: 0,
    vaCashoutLTV: 0,
    vaCashoutCLTV: 0,
    vaCashoutHCLTV: 0,
    vaFinancingOption: 'Finance',
    vaTotalLoanAmount: 0,
    vaTotalLTV: 0,
    vaTotalCLTV: 0,
    vaTotalHCLTV: 0,
    vaDownPayment: 0,
    vaDownPaymentAmount: 0,
    vaLoanHistory: 'First',
    vaFundingFee: 0,
    vaFundingFeeAmount: 0,
    vaFinanceAmount: 0,
    vaFundingFeeExempt: false,
    usdaFinancingOption: 'Finance',
    usdaTotalLoanAmount: 0,
    usdaTotalLTV: 0,
    usdaTotalCLTV: 0,
    usdaTotalHCLTV: 0,
    usdaGuaranteeFee: 0,
    usdaGuaranteeFeeAmount: 0,
    usdaFinanceAmount: 0,
    applicationDate: undefined,
    streamlineRefinanceType: undefined,
    temporaryBuydownType: undefined,
    fhaPriorEndorsementDate: undefined,
    isRelocationLoan: undefined,
    isSecondInvestorSameAsFirst: undefined,
    isSecondCommunityLoan: false,
    isSecondPiggyback: undefined,
  },
  property: {
    appraisedValue: 0,
    addressLine1: null, // use null to prevent 0 value default being set in pricing-input
    addressLine2: null,
    city: null,
    state: undefined,
    county: undefined,
    countyFipsCode: undefined,
    countyFipsCodeOnly: undefined,
    stateFipsCode: undefined,
    zipCode: null,
    msaCode: null,
    censusTract: null,
    propertyType: undefined,
    propertyAttachmentType: undefined,
    occupancy: undefined,
    units: 1,
    stories: 1,
    isNonWarrantableProject: false,
    isCondotel: false,
    inspectionWaiver: false,
  },
  borrower: {
    firstName: undefined,
    lastName: undefined,
    fico: '',
    dtiRatio: '',
    housingExpenseRatio: '',
    monthsOfReserves: 0,
    latePmt30x12: 0,
    latePmt60x12: 0,
    latePmt90x12: 0,
    latePmt120x12: 0,
    latePmt30x24: 0,
    latePmt60x24: 0,
    latePmt90x24: 0,
    latePmt120x24: 0,
    dEMonthsBKC7: undefined,
    dEMonthsBKC11: undefined,
    dEMonthsBKC13: undefined,
    dEMonthsDIL: undefined,
    dEMonthsShortSale: undefined,
    dEMonthsChargeOff: undefined,
    dEMonthsLoanMod: undefined,
    dEMonthsNoticeOfDefault: undefined,
    dEMonthsForeclosure: undefined,
    dEMultBK: 0,
    dEMultEvents: 0,
    isNonOccupancyBorrower: false,
    isNonOccupancyCoborrower: false,
    propertiesOwned: 0,
    isSelfEmployed: false,
    isFirstTimeHomeBuyer: false,
    multipleBorrowerPairs: false,
    verificationMethod: undefined,
    bankStatementsNumberOfMonthsPersonal: undefined,
    bankStatementsNumberOfMonthsBusiness: undefined,
    verificationOfEmploymentAmount: undefined,
    assetQualificationAmount: undefined,
    debtServiceCoverageRatio: undefined,
    months1099: undefined,
    creditGrade: PECreditGrade.None,
    isNonTraditionalCredit: false,
    isGiftFunds: false,
    residualIncome: 0,
    investorExperience: 0,
    fullDocMonths: undefined,
    cpaPandLMonths: undefined,
    income: 0,
    citizenship: undefined,
  },
  brokerCompPlan: {
    fixedAmount: 0,
    percent: 0,
    minAmount: 0,
    maxAmount: Infinity,
    calculatedAmount: 0,
    paidBy: compPaidByTypes.BORROWER,
  },
  criteria: {
    countyLoanLimitYear: undefined,
    desiredLockPeriod: 30,
    interestOnly: false,
    ...Object.fromEntries(
      displayLoanTypes.map(loanType => [loanType.criteriaName, false]),
    ),
  },
  mortgageInsuranceRateCards: {
    productCategory: Object.keys(MI_RATE_CARDS_PRODUCT_CATEGORIES)[0],
    isFinanced: true,
  },
  loanPrograms: defaultLoanPrograms,
  armPrograms: defaultArmPrograms,
});

const isPrimitive = val => val !== Object(val);

//Add props to this list if they should not be visible by default
const listOfPropsThatShouldNotBeVisibleByDefault = [
  'countyLoanLimitYear',
  'prepaymentPenaltyStructure',
  'prepaymentPenaltyStructureType',
  'ltv',
  'cltv',
  'hcltv',
  'downPayment',
];

const isPropDefaultVisible = prop =>
  !listOfPropsThatShouldNotBeVisibleByDefault.includes(prop);

// prop can be a simple object with props: value pair
// or value can be an object { value: 123, disabled: false }
function parseProp(propObj, defaultMap = {}) {
  Object.keys(defaultMap).forEach(prop => {
    if (!isPrimitive(propObj[prop])) {
      // the property value is a complex object of some sort
      // so deal with each control flag and the final value individually
      if (propObj[prop].disabled === undefined) propObj[prop].disabled = false;
      if (propObj[prop].visible === undefined) propObj[prop].visible = true;
      if (propObj[prop].required === undefined) propObj[prop].required = false;
      propObj[prop].value = getDefaultValue(
        propObj[prop].value,
        defaultMap[prop],
      );
    } else {
      // initialize the property with all default values
      propObj[prop] = {
        value: getDefaultValue(propObj[prop], defaultMap[prop]),
        disabled: false,
        visible: isPropDefaultVisible(prop),
        required: false,
      };
    }
  });
  return propObj;
}

/**
 * Returns a provided default value if the provided value is null, undefined, or an
 * empty string.
 *
 * @param {*} val
 * @param {*} defVal
 * @returns {*}
 */
export const getDefaultValue = (val, defVal) =>
  val == null || val === '' ? defVal : val;

export const parseLoan = loan => parseProp(loan || {}, defaultValues.loan);

export const parseProperty = property =>
  parseProp(property || {}, defaultValues.property);

export const parseBrokerCompPlan = brokerCompPlan =>
  parseProp(brokerCompPlan || {}, defaultValues.brokerCompPlan);

export const parseBorrower = borrower =>
  parseProp(borrower || {}, defaultValues.borrower);

export const parseMortgageInsuranceRateCards = mortgageInsurance =>
  parseProp(mortgageInsurance || {}, defaultValues.mortgageInsuranceRateCards);

export const parseCriteria = criteria => {
  const result = parseProp(criteria || {}, defaultValues.criteria);
  result['loanPrograms'] = parseProp(
    criteria?.loanPrograms || {},
    defaultValues.loanPrograms,
  );
  result['armPrograms'] = parseProp(
    criteria?.armPrograms || {},
    defaultValues.armPrograms,
  );
  return result;
};

/**
 * Returns a new object with the object's non-primitive values raised to the top level
 *
 * @export
 * @param {Object} propObj
 * @returns {Object}
 */
export function extractValues(propObj) {
  const returnObj = {};
  Object.keys(propObj).forEach(prop => {
    if (!isPrimitive(propObj[prop])) returnObj[prop] = propObj[prop].value;
  });
  return returnObj;
}

/**
 * Mutate template object with values from source object if template key exists
 *
 * @export
 * @param {Object} loanData
 * @param {Object} template
 * @param {Boolean} useTemplateDefaultValue - if True, don't allow empty values from the loan
 * overwrite default values in the pricer UI. This allows us to use sensible defaults
 * in the template to populate empty fields. This is enabled via the org info property
 * allow_default_logic_in_pricer_ui_templates
 * @returns {Void}
 */
export function updateTemplateWithLoanData(
  loanData,
  template,
  useTemplateDefaultValue = false,
) {
  if (!loanData) {
    return;
  }

  if (!template) {
    return;
  }

  Object.keys(loanData).forEach(prop => {
    if (!template[prop]) {
      return;
    }

    if (!isPrimitive(loanData[prop])) {
      if (loanData[prop].value) {
        template[prop].value = loanData[prop].value;
      } else if (template[prop].value) {
        template[prop].value = undefined;
      }
    } else if (
      ![null, undefined, ''].includes(loanData[prop]) ||
      !useTemplateDefaultValue
    ) {
      // Only allow empty values from loan/LOS to overwrite the default
      // in the pricer UI template if useTemplateDefaultValue is false.
      template[prop].value = loanData[prop];
    }
  });
}

export const defaultLockPeriod = 30;

export const defaultMiPaidByOptions = [
  { id: 'true', text: 'Borrower' },
  { id: 'false', text: 'Lender' },
];

export const defaultStateOptions = Object.entries(STATES).map(item => ({
  text: item[0],
  id: item[1].abbrev,
}));

export const defaultPropertyTypeOptions = Object.entries(propertyCodes).map(
  item => ({
    text: item[1],
    id: Number(item[0]),
  }),
);

export const defaultPropertyAttachmentTypeOptions = Object.entries(
  propertyAttachmentCodes,
).map(item => ({
  text: item[1],
  id: Number(item[0]),
}));

export const defaultOccupancyOptions = Object.entries(occupancyCodes).map(
  item => ({
    text: item[1],
    id: Number(item[0]),
  }),
);

export const defaultTemporaryBuydownTypeOptions = Object.entries(
  temporaryBuydownCodes,
).map(item => ({
  text: item[1],
  id: Number(item[0]),
}));

export const defaultCompPaidByOptions = Object.entries(compPaidBy).map(
  item => ({
    text: item[1],
    id: Number(item[0]),
  }),
);

export const defaultLockPeriodOptions = Object.entries(lockPeriods).map(
  item => ({
    text: item[1],
    id: Number(item[0]),
  }),
);

export const defaultLoanPurposeOptions = loanPurposeSelect;

export const defaultRefinanceTypeOptions = Object.entries(refinanceTypes).map(
  item => ({
    text: item[0],
    id: Number(item[1]),
  }),
);

export const amortizationTypeFixed = 1;

export const defaultAusOptions = Object.entries(
  AUTOMATED_UNDERWRITING_SYSTEM,
).map(item => ({
  text: item[0],
  id: item[1],
}));

export const defaultLienPositionOptions = Object.entries(LIEN_POSITIONS).map(
  item => ({
    text: item[0],
    id: item[1],
  }),
);

/**
 * Mutate destination object with values from source object (CustomParameters related)
 *
 * @export
 * @param {Object} propObj
 * @param {Object} destination
 * @returns {Void}
 */
export function insertCustomParametersValues(propObj, destination) {
  if (!propObj || !Array.isArray(propObj) || !destination) {
    return;
  }

  propObj.forEach(item => {
    const { name, value } = item;
    if (!destination.customParameters) {
      destination.customParameters = {};
    }

    if (destination.customParameters[name]) {
      destination.customParameters[name].value = value;
    } else {
      destination.customParameters[name] = { value };
    }
  });
}

/**
 * Function calculates version name for lock scenario page
 *
 * @export
 * @param {Object} item - version object
 * @returns {String} version name
 */
export function calculateVersionName(item) {
  if (!item) {
    return null;
  }

  // Get the last published time in PST
  const verDate = moment(
    item.publishingHistory[item.publishingHistory.length - 1].publishedOn,
  )
    .utc()
    .tz('America/Los_Angeles');

  // Get the current time in PST
  const curDate = moment().tz('America/Los_Angeles');

  const isSameDay =
    verDate.year() == curDate.year() &&
    verDate.month() == curDate.month() &&
    verDate.day() == curDate.day();

  const isPreviousDay =
    verDate.year() == curDate.year() &&
    verDate.month() == curDate.month() &&
    verDate.day() == curDate.day() - 1;

  let result = '';
  if (isSameDay) {
    result = 'Today';
  } else if (isPreviousDay) {
    result = 'Yesterday';
  } else {
    result = verDate.format('L');
  }
  result = result + ` @ ${verDate.format('hh:mm A')}`;
  return result;
}

export function getStateFipsFromAbbrev(abbrev) {
  return Object.values(STATES).find(state => state.abbrev === abbrev)?.fips;
}

export default {
  amortizationTypeFixed,
  defaultAusOptions,
  defaultCompPaidByOptions,
  defaultLienPositionOptions,
  defaultLoanPurposeOptions,
  defaultLockPeriod,
  defaultLockPeriodOptions,
  defaultMiPaidByOptions,
  defaultOccupancyOptions,
  defaultPropertyAttachmentTypeOptions,
  defaultPropertyTypeOptions,
  defaultRefinanceTypeOptions,
  defaultStateOptions,
  defaultTemporaryBuydownTypeOptions,
  extractValues,
  getDefaultValue,
  getStateFipsFromAbbrev,
  updateTemplateWithLoanData,
  insertCustomParametersValues,
  parseBorrower,
  parseBrokerCompPlan,
  parseCriteria,
  parseLoan,
  parseProperty,
  calculateVersionName,
};
