import {
  createRateAlert,
  updateRateAlert,
  fetchLoanRateAlerts,
} from '@pe/services/rateAlerts.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { showSuccessMessage } from '@shared/utils/notifier.js';

const CONFIRM_STEP = 'confirm';
const CONFIG_STEP = 'config';

const initialRateAlert = {
  id: null,
  name: '',
  receiverEmail: '',
  losLoanId: '',
  peRequestId: '',
  maxRate: null,
  minPrice: null,
  expiresAt: null,
  notes: '',
};

const initialConfirmationDetails = {
  lockPeriod: null,
  loanTypes: [],
  fixedPrograms: [],
  armPrograms: [],
  includeInterestOnlyProducts: false,
};

const initialState = {
  configModal: {
    isOpen: false,
    isSaving: false,
    rateAlert: { ...initialRateAlert },
    confirmationDetails: { ...initialConfirmationDetails },
    step: CONFIRM_STEP,
  },
  loan: {
    isLoading: true,
    rateAlerts: [],
  },
};

const getters = {
  loanRateAlerts: state => state.loan.rateAlerts,
  configModalTitle: state => {
    if (state.configModal.rateAlert.id) {
      return 'Update Rate Alert';
    }

    return 'Create Rate Alert';
  },
  isConfirmStep: state => state.configModal.step === CONFIRM_STEP,
  isConfigStep: state => state.configModal.step === CONFIG_STEP,
  confirmationDetails: state => state.configModal.confirmationDetails,
};

const mutations = {
  setIsConfigModalOpen(state, isOpen) {
    state.configModal = { ...state.configModal, isOpen };
  },
  setIsConfigModalSaving(state, isSaving) {
    state.configModal = { ...state.configModal, isSaving };
  },
  setRateAlertData(state, rateAlert) {
    state.configModal.rateAlert = {
      ...state.configModal.rateAlert,
      ...rateAlert,
    };
  },
  setConfirmationDetails(state, confirmationDetails) {
    state.configModal.confirmationDetails = {
      ...state.configModal.confirmationDetails,
      ...confirmationDetails,
    };
  },
  resetRateAlertData(state) {
    state.configModal.rateAlert = { ...initialRateAlert };
  },
  resetConfigModal(state) {
    state.configModal.rateAlert = { ...initialRateAlert };
    state.configModal.step = CONFIRM_STEP;
    state.configModal.confirmationDetails = { ...initialConfirmationDetails };
  },
  setExistingRateAlert(state, rateAlert) {
    state.existingRateAlert = { ...rateAlert };
  },
  setLoanRateAlerts(state, rateAlerts) {
    state.loan.rateAlerts = rateAlerts.map(rateAlert => {
      return {
        ...rateAlert,
        maxRate: Number(rateAlert.maxRate).toString(),
        minPrice: Number(rateAlert.minPrice).toString(),
        expiresAt: new Date(rateAlert.expiresAt),
      };
    });
  },
  setIsLoanRateAlertsLoading(state, isLoading) {
    state.loan.isLoading = isLoading;
  },
  resetLoanRateAlerts(state) {
    state.loan.rateAlerts = [];
  },
  goToConfigStep(state) {
    state.configModal.step = CONFIG_STEP;
  },
  goToConfirmStep(state) {
    state.configModal.step = CONFIRM_STEP;
  },
};

const actions = {
  closeConfigModal({ commit }) {
    commit('setIsConfigModalOpen', false);
    commit('resetConfigModal');
  },
  async createOrUpdateRateAlert(context) {
    const { commit, rootGetters, state, dispatch } = context;
    try {
      commit('setIsConfigModalSaving', true);

      const createOrUpdate = state.configModal.rateAlert.id
        ? updateRateAlert
        : createRateAlert;
      const orgId = rootGetters['organizations/currentOrganizationId'];
      const response = await createOrUpdate(orgId, state.configModal.rateAlert);
      commit('setRateAlertData', response);
      showSuccessMessage('Rate alert created');
    } catch (e) {
      onErrorHandler(e, 'rateAlert');
    } finally {
      commit('setIsConfigModalSaving', false);
      dispatch('closeConfigModal');
    }
  },
  async getLoanRateAlerts({ commit }, { orgId, losLoanId }) {
    try {
      commit('setIsLoanRateAlertsLoading', true);
      const response = await fetchLoanRateAlerts(orgId, losLoanId);
      commit('setLoanRateAlerts', response.results);
    } catch (e) {
      onErrorHandler(e, 'rateAlert');
    } finally {
      commit('setIsLoanRateAlertsLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
  actions,
};
